import React, { useEffect } from "react"
import styled from "styled-components"
import Button from "../components/button"
import Layout from "../components/layout"

const StyledOrderPage = styled.div`
  position: relative;
  .index-image-container {
    position: absolute;
    right: -20%;
    transition: 1.5s ease-in-out;
    bottom: 0;
    opacity: 0;
    img {
      width: 38vw;
      max-width: 1000px;
      display: block;
    }
    @media (max-width: 768px) {
      top: 60%;
      img {
        width: 100%;
      }
    }
  }
`

const OrderPage = () => {
  const onMount = () => {
    const content = document.querySelector(".index-content")
    const image = document.querySelector(".index-image-container")
    setTimeout(() => {
      content.style.transform = "translateY(0)"
      content.style.opacity = 1
    }, 200)
    setTimeout(() => {
      image.style.opacity = 1
      image.style.right = 0
    }, 500)
  }

  useEffect(() => {
    onMount()
  }, [])
  return (
    <Layout title="Beställ">
      <StyledOrderPage className="page">
        <div />
        <div className="index-content">
          <h1>
            En isbräda byggd
            <br />
            för racingbanan.
          </h1>
          <p>
            Vi tillverkar isbrädor som ska vara roliga att köra, prestera bra på
            tävling och dessutom fungera i alla väderförhållanden. Vare sig du
            är en erfaren proffsåkare eller provar issurfing för första gången.
          </p>
          <a
            href="mailto:sharp4@live.se?subject=Beställning%20%E2%80%93%20Isbräda"
            className="order-button"
          >
            <Button>Beställ</Button>
          </a>
        </div>
        <div />
        <div className="index-image-container">
          <img src="/images/iceboard_standing.png" alt="iceboard go" />
        </div>
      </StyledOrderPage>
    </Layout>
  )
}

export default OrderPage
